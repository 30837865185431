<template>
  <div class="p-6 flex-auto">
    <h2 class="text-xl font-semibold text-gray-800 mb-3">Captcha</h2>
    <div class="flex flex-col mt-6">
      <div class="flex  items-center mb-3 gap-5">
        <div class="text-sm text-gray-600 font-medium mb-1">CloudFlare Turnstile captcha</div>
        <div class="form-switch">
          <input
            v-model="challenge"
            type="checkbox"
            id="captcha-checkbox"
            class="sr-only"
            :true-value="true"
            :false-value="false"
          />
          <label class="bg-gray-400" for="captcha-checkbox">
            <span class="bg-white shadow-sm" aria-hidden="true"></span>
            <span class="sr-only">Toggle</span>
          </label>
        </div>
      </div>
      <div class="flex flex-col mt-3">
        <div class="flex flex-col gap-4" v-if="challenge">
          <div class="mr-2.5">
            <label class="block text-sm font-medium mb-1 text-gray-600" for="api-captcha-key">CloudFlare Turnstile API key</label>
            <div class="relative">
              <input
                v-model="turnstile_api_key"
                id="api-captcha-key"
                class="form-input w-full min-w-[370px] pr-[35px]"
                type="text"
                placeholder="CloudFlare Turnstile API key"
              />
            </div>
          </div>
          <div class="mr-2.5">
            <label class="block text-sm font-medium mb-1 text-gray-600" for="captcha-key">CloudFlare Turnstile secret key</label>
            <div class="relative">
              <input
                v-model="turnstile_secret"
                id="captcha-key"
                :class="{
                  'text-transparent' : !showCaptchaSecretKey
                }"
                :style="showCaptchaSecretKey ? '' : 'text-shadow: 0 0 5px rgba(0,0,0,0.5);'"
                class="form-input w-full min-w-[370px] pr-[35px]"
                type="text"
                placeholder="CloudFlare Turnstile secret key"
              />
              <EyeOffIcon
                v-if="!showCaptchaSecretKey"
                @click.stop="toggleCaptchaSecretKey"
                class="h-5 w-5 text-gray-400 hover:text-gray-600 transition-all absolute transform-translate-y top-1/2 right-2"
              />
              <EyeIcon
                v-else
                @click.stop="toggleCaptchaSecretKey"
                class="h-5 w-5 text-gray-400 hover:text-gray-600 transition-all absolute transform-translate-y top-1/2 right-2"
              />
            </div>
          </div>
        </div>
        <button
          class="btn self-start mt-6 min-w-[80px] bg-indigo-500 hover:bg-indigo-600 text-white disabled:border-gray-200 disabled:bg-gray-100 disabled:text-gray-400 disabled:cursor-not-allowed"
          :disabled="loadingUpdate || disabledSaveBtn"
          @click="save"
        >
          <svg v-if="loadingUpdate" class="animate-spin w-4 h-4 fill-current shrink-0" viewBox="0 0 16 16">
            <path d="M8 16a7.928 7.928 0 01-3.428-.77l.857-1.807A6.006 6.006 0 0014 8c0-3.309-2.691-6-6-6a6.006 6.006 0 00-5.422 8.572l-1.806.859A7.929 7.929 0 010 8c0-4.411 3.589-8 8-8s8 3.589 8 8-3.589 8-8 8z" />
          </svg>
          <span :class="{ 'ml-2' : loadingUpdate }">Save</span>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapFields } from 'vuex-map-fields'
import { EyeOffIcon, EyeIcon } from '@heroicons/vue/outline'

export default {
  name: 'SettingsCaptcha',
  data: () => ({
    showCaptchaSecretKey: true,
    loadingUpdate: false
  }),
  components: {
    EyeOffIcon,
    EyeIcon,
  },
  watch: {
    profile_id: {
      handler(val) {
        this.$nextTick(() => {
          if(val && this.turnstile_secret?.length) {
            this.toggleCaptchaSecretKey()
          }
        })
      },
      deep: true,
      immediate: true
    },
  },
  computed: {
    ...mapFields('ProfileModule', {
      profile_id: 'id',
      challenge: 'challenge',
      turnstile_api_key: 'turnstile_api_key',
      turnstile_secret: 'turnstile_secret'
    }),
    disabledSaveBtn() {
      return this.challenge && !this.turnstile_secret && !this.turnstile_api_key
    }
  },
  methods: {
    toggleCaptchaSecretKey() {
      if(!this.turnstile_secret?.length) {
        return
      }
      this.showCaptchaSecretKey = !this.showCaptchaSecretKey
    },
    async save() {
      try {
        this.loadingUpdate = true
        const payload = {
          challenge: this.challenge
        }
        if(this.turnstile_api_key) {
          payload.turnstile_api_key =  this.turnstile_api_key
        }
        if(this.turnstile_secret) {
          payload.turnstile_secret =  this.turnstile_secret
        }
        await this.$store.dispatch('ProfileModule/UPDATE_PROFILE', payload)
      } finally {
        this.loadingUpdate = false
      }
    }
  }
}
</script>
